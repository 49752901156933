.breadcrumb {
  list-style: none;
  overflow: hidden;
  font: 18px Helvetica, Arial, Sans-Serif;
  text-orientation: right;
}

.breadcrumb li {
  float: right;
}

.breadcrumb li a {
  font-size: 12px;
  color: #a0a0a0;
  text-decoration: none;
  padding: 5px 55px 5px 0;
  background: #dadada;
  /* fallback color */
  background: #dadada;
  position: relative;
  display: block;
  float: right;
  font-weight: bolder;
}

.breadcrumb li a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-right: 30px solid #dadada;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  right: 100%;
  z-index: 2;
}

.breadcrumb li a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-right: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-right: 1px;
  right: 100%;
  z-index: 1;
}

.breadcrumb li a.active {
  background: #c71b1a;
  color: white;
  padding-right: 40px;
}

.breadcrumb li a.active:hover {
  color: black;
}

.breadcrumb li a.active::after {
  border-right: 30px solid #c71b1a;
}

.breadcrumb li a.active::before {
  border-right: 30px solid #c71b1a;
}

.breadcrumb li:first-child a {
  padding-right: 15px;
}

.breadcrumb li a:hover {
  background: #ffe4e4;
}

.breadcrumb li a:hover:after {
  border-right-color: #ffe4e4 !important;
}
