@font-face {
  font-family: iransans;
  font-weight: 200;
  src: url(../fonts/iransans_ultralight.woff) format("woff");
}
@font-face {
  font-family: iransans;
  font-weight: 300;
  src: url(../fonts/iransans_light.woff) format("woff");
}
* {
  font-family: iransans;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  direction: rtl;
}
.badge {
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 11px;
  margin-left: 8px;
}
.badge:last-child {
  margin-left: 0;
}
.badge.red-light {
  background: #ffdfdf;
}
.box {
  background-color: white;
  border: 1px solid #d6d6d6;
  box-shadow: 0 3px 3px 0.1px #cacaca;
  padding: 10px 15px;
  font-weight: 500;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
blockquote {
  border-right: 2px solid gainsboro;
  padding-right: 10px;
}
ul.inline-list {
  direction: rtl;
  padding: 0;
}
ul.inline-list li.inline-item {
  display: inline-block;
  margin-left: 32px;
}
ul.inline-list li.inline-item:last-child {
  margin-left: 0;
}
ul.inline-list li.inline-item a {
  text-decoration: none;
  font-weight: bold;
  color: #5a5a5a;
}
@media (max-width: 576px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 60%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 80%;
  }
}
@media (min-width: 1600px) {
  .container {
    width: 50%;
  }
}
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: whitesmoke;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
a {
  text-decoration: none;
  color: inherit;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: white;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0 3px 3px 0.1px #cacaca;
  padding: 15px 0;
}
.header .inner {
  display: flex;
  justify-content: space-between;
}
.input-search-container {
  position: relative;
  width: 320px;
}
@media (max-width: 576px) {
  .input-search-container {
    width: 300px;
  }
}
.input-search-container .input-search {
  border: 1px solid #d6d6d6;
  width: 100%;
  height: 43px;
  font-size: 12px;
  direction: rtl;
  padding: 0 10px;
  background: #f9f9f9;
}
.input-search-container .input-search-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 43px;
  height: 43px;
}
.input-search-container .input-search-icon-container img {
  height: 22px;
}
.person-card-container {
  border: 1px solid rgb(169 169 169);
  position: relative;
  width: 100%;
  height: 142px;
}
.person-card-container img.person-card-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.person-card-container .person-brief-container {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.person-card-container .person-brief-container .person-brief-box {
  border: 1px solid #c9c9c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: auto;
  background: white;
  margin: 0px auto;
}
.person-card-container .person-brief-container .person-brief-box label:first-child {
  font-size: 12px;
  font-weight: bolder;
}
.person-card-container .person-brief-container .person-brief-box label:last-child {
  font-size: 10px;
  color: #6d6d6d;
}
.tbl .table-row {
  display: flex;
  background-color: white;
  border: 1px solid #d6d6d6;
  flex-direction: row-reverse;
}
.tbl .table-row:nth-child(2n) {
  background: #f7f7f7;
}
.tbl .table-row .table-data {
  padding: 12px;
  direction: rtl;
  border-left: 1px solid #d6d6d6;
}
.tbl .table-row .table-data:last-child {
  border-left: none;
}
.container-media-main {
  width: 100%;
  height: 480px;
  position: relative;
}
.container-media-main img.media-source {
  position: absolute;
  width: 100%;
  height: 100%;
}
.container-media-main div.media-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-media-main div.media-play img.media-play-button {
  width: 64px;
  height: 64px;
}
.gallery {
  overflow-x: scroll;
  width: 100%;
  display: flex;
  padding: 15px 0;
}
.gallery .gallery-item {
  position: relative;
  margin-right: 10px;
  width: 230px;
  height: 120px;
  flex-shrink: 0;
}
.gallery .gallery-item:last-child {
  margin-right: 0;
}
.gallery .gallery-item img {
  width: 100%;
  height: 100%;
}
.gallery .gallery-item div.item-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery .gallery-item div.item-play img.item-play-button {
  width: 32px;
  height: 32px;
}
